import React from "react";

const Policy = () => {
  return (
    <div className="_block-container_3ik3e_44" data-id="6qsrkem-mgDA_5hphjgdR">
      <section className="w-full mx-auto max-w-7xl py-8 bg-white sm:px-6 px-4 md:py-16">
        <div className="container">
          <h2 className="TITLE-PRIMARY text-4xl font-semibold text-slate-900 sm:text-4xl xl:text-5xl font-pj ">
            Privacy Policy
          </h2>

          <p className="DESC mb-4 text-slate-600 mt-4">
            At Speedy Set, operated by Inovestr Global Private Limited, we
            understand the importance of protecting the privacy of our users.
            This Privacy Policy outlines how we collect, use, and protect your
            personal information when you use our website, Speedy Set.com. By
            accessing or using our website, you agree to the terms of this
            Privacy Policy.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            1. Information Collected
          </h2>
          <p className="DESC mb-4 text-slate-600">
            We collect various types of information to provide and improve our
            services, including:
            <ul className="list-disc ml-6 mt-2">
              <li>
                Log Files: Our web server uses an extended log file format which
                captures the date and time of your visit, referring address
                (location from which you came to the Speedy Set website), type
                of Internet browser, and your IP address.
              </li>
              <li>
                Personal Information: Several areas of the Speedy Set website
                ask for personal information, including the username
                registration, managing your account, and registration pages for
                various online services. The information collected may include
                your name, address, email address, billing information, and
                business profile.
              </li>
            </ul>
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            2. Use of Information
          </h2>
          <p className="DESC mb-4 text-slate-600">
            The information collected on our website is used to market and
            improve SpeedySet’s services, enhance the content and services on
            our website, and make it more user-friendly and relevant to
            individual users. We may use this information to contact you with
            updates about our website or for other marketing purposes, with your
            permission. We never share or sell any information obtained from our
            website to any other organization, except as required by law.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            3. Registration and Cookies
          </h2>
          <p className="DESC mb-4 text-slate-600">
            Certain areas of our website, such as 'Tracking', 'Service Guide',
            and 'Corporate Solutions', require registration or a password for
            access. The information obtained from registered users may be used
            for SpeedySet’s marketing purposes, and cookies may be used in these
            areas to improve the user experience. Cookies are used to
            personalize information for certain segments of our customer base
            and to associate individual customers with their information
            profiles. For example, cookies allow the website to remember and
            auto-fill your user ID when you log in. Cookies can be removed from
            your hard drive, and you may be able to change the properties on
            your cookie file so that cookies are not used or saved, depending on
            your web browser and version.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            4. Use of Third-Party Links
          </h2>
          <p className="DESC mb-4 text-slate-600">
            Parts of the Speedy Set website may consist of products and services
            hosted by subsidiaries and third-party businesses. When you leave
            our website to visit one of these sites, the only information
            transferred to the third-party is that you came from the Speedy Set
            website. We are not responsible for the privacy practices of
            third-party sites. You are advised to consult the privacy policies
            at those sites to determine how your information may be used.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            5. Security and Fraud Prevention
          </h2>
          <p className="DESC mb-4 text-slate-600">
            Speedy Set takes the security of your information seriously.
            However, be aware of fraudulent emails that may appear to be from
            Speedy Set, requesting personal or financial information. If you
            receive such emails, do not open them or click on any attachments.
            Delete the email immediately. Common warning signs of online scams
            include unexpected requests for money in return for delivery of a
            package, requests for personal or financial information, links to
            misspelled or altered website addresses, and claims of winning a
            large sum of money.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            6. Shipment Data
          </h2>
          <p className="DESC mb-4 text-slate-600">
            Certain shipment data will be provided to the authorities of the
            country of transit or destination for customs and tax clearance or
            for security screening, as required by the laws of such country.
            This information may include shipper and receiver names and
            addresses, description of goods, number of pieces, weight, and value
            of the shipment.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500">
            7. Contact Information
          </h2>
          <p className="DESC mb-4 text-slate-600">
            For any questions or concerns about this Privacy Policy or the
            handling of your personal information, please contact us at:
          </p>

          <div className="row d-flex flex-column font-semibold ">
            <p className="fs-18 fw-700">Inovestr Global Private Limited</p>
            <p className="fs-18">
              C/o Bhagvana ram Surja, ram swami rampura, Rampura Ramsara,
              Hanumangarh, Tibbi, Rajasthan, India, 335526
            </p>
            <a
              href="mailto:karmakarvis@gmail.com"
              className="fs-18"
              style={{ cursor: "pointer" }}
            >
              karmakarvis@gmail.com
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Policy;
