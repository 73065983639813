import React from "react";
import { aboutUsData, contactUsData } from "../mock";
import aboutUs from "../images/comm2.jpg";
import aboutImg from "../images/aboutUs.webp";

const AboutUs = () => {
  return (
    <>
      <div
        className="_block-container_3ik3e_44"
        data-id="cVt4im351Gxi1hUxJ3jTU"
      >
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 px-6 lg:px-8">
          <div
            className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-20 _wrap_9anij_44"
            data-element-type="image"
            style={{
              transform: "none",
              opacity: 1,
              objectFit: "none",
              background: "transparent",
              borderRadius: "inherit",
            }}
          >
            <img
              alt="about"
              className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-20 _editable-img_9anij_47"
              src={aboutUs}
              style={{ backgroundColor: "transparent" }}
            />
          </div>
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=About%20FastTrack%20Delivery"
                    className="_editable_5dd2t_44"
                  >
                    About Speedy Set
                  </div>
                </span>
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Learn%20more%20about%20our%20mission%2C%20values%2C%20and%20the%20team%20that%20drives%20our%20success."
                    className="_editable_5dd2t_44"
                  >
                    Learn more about our mission, values, and the team that
                    drives our success.
                  </div>
                </span>
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
              {aboutUsData?.map((data, index) => {
                return (
                  <div
                    className="flex gap-x-4 rounded-xl  bg-white/5 backdrop-blur-xl p-6 ring-1 ring-inset ring-white/10"
                    key={index}
                  >
                    <div className="text-base leading-7">
                      <h3 className="font-semibold text-white">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Our%20Mission"
                            className="_editable_5dd2t_44"
                          >
                            {data?.title}
                          </div>
                        </span>
                      </h3>
                      <p className="mt-2 text-gray-300">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Delivering%20your%20packages%20swiftly%20and%20reliably."
                            className="_editable_5dd2t_44"
                          >
                            {data?.desc}
                          </div>
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/*  <div
        className="_block-container_3ik3e_44"
        data-id="OPCGsUIkOJoGtLjz8J9wk"
      >
        <div className="w-full">
          <div className="max-w-7xl mx-auto py-20 px-4">
            <div className="max-w-md">
              <h1 className="TITLE-PRIMARY text-5xl font-semibold text-slate-900 mb-6">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Join%20Our%20Team"
                    className="_editable_5dd2t_44"
                  >
                    Join Our Team
                  </div>
                </span>
              </h1>
              <p className="DESC text-base font-normal text-slate-700">
                <span>
                  <div
                    data-element-type="text"
                    data-link="link=&amp;target=_blank&amp;text=Explore%20exciting%20career%20opportunities%20at%20FastTrack%20Delivery."
                    className="_editable_5dd2t_44"
                  >
                    Explore exciting career opportunities at Speedy Set.
                  </div>
                </span>
              </p>
            </div>
            <ul className="mt-12 space-y-3 divide-y divide-black/10 ">
              {ourTeam?.map?.((data,index) => {
                return (
                  <li className="px-4 py-5 duration-150 hover:border-black/10 hover:rounded-xl hover:bg-gray-50" key={index}>
                    <button data-element-type="button" className="space-y-3">
                      <div className="flex items-center gap-x-3">
                        <div className="IMAGE bg-white w-14 h-14 border border-black/10 rounded-full flex items-center justify-center">
                          <span>
                            <FaBuilding />
                          </span>
                        </div>
                        <div className="flex flex-col gap-1">
                          <span className="text-sm text-left text-sky-600 font-medium">
                            <span>
                              <div
                                data-element-type="text"
                                data-link="link=&amp;target=_blank&amp;text=FastTrack%20Delivery"
                                className="_editable_5dd2t_44"
                              >
                                Speedy Set
                              </div>
                            </span>
                          </span>
                          <h3 className="TITLE-SECONDARY text-lg text-left font-semibold text-slate-900 ">
                            <span>
                              <div
                                data-element-type="text"
                                data-link="link=&amp;target=_blank&amp;text=Logistics%20Coordinator"
                                className="_editable_5dd2t_44"
                              >
                                {data?.name}
                              </div>
                            </span>
                          </h3>
                        </div>
                      </div>
                      <p className="DESC text-left text-slate-700 ">
                        <span>
                          <div
                            data-element-type="text"
                            data-link="link=&amp;target=_blank&amp;text=Coordinate%20and%20oversee%20the%20logistics%20of%20our%20delivery%20operations."
                            className="_editable_5dd2t_44"
                          >
                            {data?.desc}
                          </div>
                        </span>
                      </p>
                      <div className="text-sm text-slate-700  flex items-center gap-6">
                        <span className="flex items-center gap-2">
                          <span>
                            <FaBriefcase />
                          </span>
                          <span>
                            <div
                              data-element-type="text"
                              data-link="link=&amp;target=_blank&amp;text=Full-time"
                              className="_editable_5dd2t_44"
                            >
                              {data?.time}
                            </div>
                          </span>
                        </span>
                        <span className="flex items-center gap-2">
                          <span>
                            <MdLocationOn />
                          </span>
                          <span>
                            <div
                              data-element-type="text"
                              data-link="link=&amp;target=_blank&amp;text=Remote"
                              className="_editable_5dd2t_44"
                            >
                              {data?.work}
                            </div>
                          </span>
                        </span>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div> */}
      <div
        className="_block-container_3ik3e_44"
        data-id="rcoMQPWy9joU4JG0cv6MW"
      >
        <section className="relative py-6 overflow-hidden bg-white sm:py-8 md:py-12 lg:py-16 xl:py-20">
          <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="md:flex block gap-2 items-center">
              <div className="w-full">
                <div>
                  <h4 className="text-[36px] pb-4 font-bold">Who we are</h4>
                  <p className="font-base">
                    At Inovestr Global Private Limited, we are dedicated to transforming the
                    way you track and manage your courier orders. With a
                    commitment to innovation and customer satisfaction, we have
                    developed a seamless platform that offers real-time
                    tracking, ensuring you are always informed about the status
                    of your deliveries. Our service is built on a foundation of
                    transparency, reliability, and efficiency, making it easier
                    than ever to stay connected with your shipments.
                  </p>
                  <p className="font-base">
                    Founded with the vision of simplifying the logistics
                    process, we understand the importance of timely and accurate
                    information in today's fast-paced world. Whether you're a
                    business sending out large volumes of packages or an
                    individual awaiting a special delivery, our platform
                    provides the tools you need to monitor your shipments with
                    ease. We strive to bridge the gap between couriers and
                    customers, offering an intuitive interface that delivers
                    up-to-the-minute updates, so you can plan your day without
                    the stress of uncertainty.
                  </p>
                </div>
              </div>
              <div className="w-full text-center">
                <img className="md:w-[400px] w-[280px]  md:pt-0 pt-4 m-auto" src={aboutImg} alt="" />
              </div>
            </div>
            {/* <div className="flex flex-col ">
              <div className="flex w-full flex-col">
                <div>
                  <h2 className="TITLE-PRIMARY text-4xl font-semibold text-black  md:text-5xl">
                    <span>
                      <div
                        data-element-type="text"
                        data-link="link=&amp;target=_blank&amp;text=Contact%20Us"
                        className="_editable_5dd2t_44"
                      >
                        Contact Us
                      </div>
                    </span>
                  </h2>
                  <p className="DESC mt-6 font-normal text-black/70">
                    <span>
                      <div
                        data-element-type="text"
                        data-link="link=&amp;target=_blank&amp;text=Get%20in%20touch%20with%20FastTrack%20Delivery%20for%20any%20inquiries."
                        className="_editable_5dd2t_44"
                      >
                        Get in touch with Speedy Set for any inquiries.
                      </div>
                    </span>
                  </p>
                </div>
                <ul className="mt-16 flex flex-col items-center justify-between gap-5 lg:flex-row">
                  {contactUsData?.map((data, index) => {
                    return (
                      <li
                        className="mt-4 flex items-center rounded-xl w-full h-32 p-6 ring-1 ring-inset ring-black/10"
                        key={index}
                      >
                        <div className="inline-flex items-center justify-center w-10 h-10 text-black bg-sky-500 rounded-full shrink-0">
                          <span>{data?.icon}</span>
                        </div>
                        <div className="ml-4 sm:ml-6">
                          <h3 className="TITLE-SECONDARY  font-semibold text-black ">
                            <span>
                              <div
                                data-element-type="text"
                                data-link="link=&amp;target=_blank&amp;text=Our%20Address"
                                className="_editable_5dd2t_44"
                              >
                                {data?.title}
                              </div>
                            </span>
                          </h3>
                          <p className="DESC mt-1 text-sm font-normal text-black/70">
                            <span>
                              <div
                                data-element-type="text"
                                data-link="link=&amp;target=_blank&amp;text=123%20FastTrack%20Ln.%0ASpeedy%20City%2C%2098765"
                                className="_editable_5dd2t_44"
                              >
                                {data?.desc}
                              </div>
                            </span>
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
