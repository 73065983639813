import React from "react";

const Terms = () => {
  return (
    <div className="_block-container_3ik3e_44" data-id="6qsrkem-mgDA_5hphjgdR">
      <section className="w-full  mx-auto max-w-7xl py-8 sm:px-6 px-4 bg-white md:py-16">
        <div className="container">
          <h2 className="TITLE-PRIMARY text-4xl font-semibold text-slate-900 sm:text-4xl xl:text-5xl font-pj ">
            Terms and Conditions
          </h2>

          <p className="DESC text-slate-600  mt-4">
            Welcome to SpeedySet.com, a website operated by Inovestr Global Private Limited (“SpeedySet”). The following terms and conditions govern your use of SpeedySet’s website and services. By accessing or using SpeedySet.com, you agree to be bound by these terms and conditions. If you do not agree with these terms, please do not use our website.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Definitions:
          </h2>
          <p className="DESC text-slate-600">
            “Content” refers to all information, graphics, products, features, functionality, services, and links available on SpeedySet.com, including but not limited to ‘Tracking’, ‘Service Guide’, and ‘Corporate Solutions’.
            <br/>
            “SpeedySet” refers to Inovestr Global Private Limited, located at 001, Senapati, C/O NG Romi, Taphou Ngaihang, Senapati, Manipur, 795106.
            <br/>
            “You” refers to the user or viewer of SpeedySet.com.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Use of Speedy Set Website:
          </h2>
          <p className="DESC text-slate-600">
            SpeedySet.com is intended solely for use by current and potential Speedy Set customers to interact with Speedy Set. The site is provided for purposes such as shipping, tracking, obtaining shipping rates, receiving invoices, making payments, identifying drop-off locations, and preparing international documents.
            <br/>
            Unauthorized use of SpeedySet.com, including but not limited to accessing services or information for third parties, is strictly prohibited.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            User Registration:
          </h2>
          <p className="DESC text-slate-600">
            Users may register on SpeedySet.com to access interactive features. By registering, you agree to provide accurate and current information about yourself. You are responsible for maintaining the confidentiality of your password and account information and for all activities under your account.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Changes to the Website:
          </h2>
          <p className="DESC text-slate-600">
            Speedy Set reserves the right to modify, suspend, or discontinue any part of the website or its content at any time without notice.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Termination of Use:
          </h2>
          <p className="DESC text-slate-600">
            Speedy Set may, at its sole discretion, terminate or suspend your access to the website for any reason, including but not limited to violations of these terms and conditions.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Ownership:
          </h2>
          <p className="DESC text-slate-600">
            All content on SpeedySet.com is the property of Speedy Set and is protected by applicable intellectual property laws. Users may not copy, modify, distribute, or reverse engineer any part of the website or its content.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Disclaimer of Warranty:
          </h2>
          <p className="DESC text-slate-600">
            SpeedySet.com and its content are provided “AS IS” without warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose.
            <br/>
            Speedy Set does not warrant that the website will be uninterrupted or error-free, nor does it make any warranty as to the results that may be obtained from the use of the website.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Limitation of Liability:
          </h2>
          <p className="DESC text-slate-600">
            Speedy Set will not be liable for any damages arising from the use or inability to use the website, including but not limited to direct, indirect, incidental, or consequential damages.
            <br/>
            In no event shall SpeedySet’s total liability exceed INR 100 for domestic consignments and USD 100 for international consignments.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Indemnity:
          </h2>
          <p className="DESC text-slate-600">
            You agree to indemnify and hold Speedy Set harmless from any claims arising from your use of the website or violation of these terms and conditions.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Links to Other Websites:
          </h2>
          <p className="DESC text-slate-600">
            SpeedySet.com may contain links to third-party websites. Speedy Set is not responsible for the content or privacy practices of these websites and does not endorse or make any representations about them.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Governing Law:
          </h2>
          <p className="DESC text-slate-600">
            These terms and conditions are governed by the laws of the Republic of India. Any disputes arising from these terms or your use of SpeedySet.com will be subject to the exclusive jurisdiction of the courts in Manipur, India.
          </p>

          <h2 className="TEXT-LINK text-lg font-semibold text-sky-600 hover:text-sky-700 duration-500 mt-4">
            Contact Information:
          </h2>
          <p className="DESC text-slate-600">
            For any queries regarding these terms and conditions, please contact us at:
          </p>

          <div className="row d-flex flex-column mt-4  font-semibold">
            <p className="fs-18 fw-700">Need assistance? Reach out to us:</p>
            <a
              href="mailto:karmakarvis@gmail.com"
              className="fs-18"
              style={{ cursor: "pointer" }}
            >
              karmakarvis@gmail.com
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
